.com1 {
    width: 285px;
    height: 470px;
}
.com1 h3 {
    font-size: 21px;
    color: #fdeb95;
    text-align: center;
    letter-spacing: 1px;
}
.com1 i {
    font-size: 18px;
    color: White;
    text-align: center;
}