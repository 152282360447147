/* Default styles */
.responsive-modal-body {
    height: 550px;
  }

  .modal-image {
    height: 490px;
  }

  /* Adjust modal and image height for mobile view */
  @media (max-width: 768px) {
    .responsive-modal-body {
      height: 300px !important;
    }

    .modal-image {
      height: 190px;
    }
  }

  @media (max-width: 576px) {
    .responsive-modal-body {
      height: 300px;
    }

    .modal-image {
      height: 190px;
    }
  }