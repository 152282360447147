ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0px !important;
  text-align: justify;
}

ul li {
  display: block;
  position: relative;
  float: left;
}


@media screen and (max-width: 768px) {
  ul li {
    display: block;
    position: relative;
    float: none;
    width: 100%;
  }
}

.main-navigation li.active {
  background-color: #e0ab08;
}

li ul {
  display: none;
}

ul li a {
  display: block;
  padding: 1em;
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
}

@media (max-width: 768px) {

  ul li a:hover,
  ul li a:active,
  ul li:active,
  .mobileview ul li:active {
    color: #e0ab08;
    height: 38px;
  }
}

li:hover>ul {
  display: block;
  position: absolute;
  z-index: 9;
}

li:hover li {
  float: none;
  border-bottom: 1px solid #fff;
}

li:hover li:last-child {
  border-bottom: none;
}

.main-navigation li:hover a {
  background: #0d4571;
}

li:hover li a:hover {
  background: #e0ab08;
}

.main-navigation li ul li {
  border-top: 0;
}

ul ul ul {
  left: 100%;
  top: 0;
}

ul:before,
ul:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

ul:after {
  clear: both;
}

/*Mobile view Styless */

.dropdown-toggle::after {
  content: "";
  display: none;
}

.mobileview ul li:hover>ul {
  display: block;
  position: absolute;
  z-index: 9;
  width: 100%;
}

.mobileview ul li a:active {
  background: #e0ab08;
  width: 16rem !important;
  height: 38px;
}

/* news card design */
.card {
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
  border: none;
  margin-bottom: 30px;
}

.card-01 .card-body {
  position: relative;
  padding-top: 10px;
}

.card-01 .badge-box {
  position: absolute;
  top: -20px;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  text-align: center;
}

.card-01 .badge-box i {
  background: #02c386;
  color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
}

.card-01 .height-fix {
  height: 455px;
  overflow: hidden;
}

.card-01 .height-fix .card-img-top {
  width: auto !important;
}

.card-title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
}


#btnmore {
  background-color: #02c386 !important;
  border: none;
  font-size: 11px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  max-width: 100%;
  box-sizing: border-box;
  width: auto;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  #btnmore {
    font-size: 8px;
    padding: 8px 8px;
  }
}


#btnmore:hover {
  background-color: #e0ab08 !important;
  border: none;

.navbar ul li a:hover {
  border-bottom: 3px solid #ffbf00;
  border-radius: 0px 0px 5px 5px;
}

}

@media only screen and (max-width: 767px) {
  #sp-section-3 img {
    height: 50px;
    width: 100% !important;
  }
}