
.rs-services {
    padding: 20px 0;
}

.rs-services .section-heading {
    text-align: center;
}

.services-column {
    display: flex;
    justify-content: center;
}

.services-wrap {
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    transition: all 0.3s ease;
}

.services-wrap img {
    width: 100%;
    height: 108px;
    object-fit: cover;
}

.services-txt {
    margin-top: 10px;
}

.services-title {
    font-size: 16px;
    color: #333;
}

@media (max-width: 768px) {
    .rs-services {
        background: none;
    }

    .services-column {
        width: 33.33% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .services-wrap {
        height: auto;
        box-shadow: none;
        border-radius: 10px;
        padding: 5px;
        margin: 5px;
    }

    .services-wrap img {
        max-height: 76px;
    }

    .services-title {
        font-size: 14px !important;
    }
    
    .row {
        display: flex;
        flex-wrap: wrap;
    }
}

.services-title:hover {
    color: #e0ab08 !important;
}
