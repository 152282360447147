/* Page Setup
================================*/
.vh-section-outer {
  display: table;
  width: 100%;
  height: 30vh;
}

.section-inner {
  display: table-cell;
  vertical-align: middle;
}

.section-bg {
  background-size: cover;
  background-position: center;
}

/* Tab Setup
===============================*/
.tabSection {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  height: auto;
  margin-bottom: 20px;
  padding-bottom: 0px;
  padding-top: 6px;
  position: relative;
  z-index: 1;
}

.tab-content {
  height: auto;
  padding: 40px 30px;
}

/* nav-pills
===============================*/

.nav-pills {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.nav-pills>li.category-link {
  flex: 1;
  text-align: center;
  min-height: 45px;
  margin: 10px;
}

.nav-pills>li.category-link>a {
  position: relative;
  display: block;
  width: 100%;
  padding: 15px 15px;
  background-color: #dadada;
  font-weight: 500;
  border-radius: 4px;
  transition: color, background-color 0.3s ease;
  font-size: 18px;
}

.nav-pills>li.category-link.active a {
  color: #000000;
  background-color: #02c386;
}

.nav-pills>li.category-link>a:hover {
  text-decoration: none;
  background-color: #f6c93f;
  color: rgb(255, 255, 255);
  transition: all 0.3s ease;
}

/* .nav-pills>li.category-link.active::after {
  position: absolute;
  bottom: -39px;
  height: 0;
  width: 0;
  left: 50%;
  transform: rotate(180deg);
  border: 20px solid transparent;
  border-bottom-color: #02c386;
  content: "";
} */

a {
  color: #333333;
}

a p {
  margin-bottom: 0px;
  color: #000000;
}

/* tab-content
===============================*/
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
}

/* Media Queries
===============================*/

@media (max-width: 768px) {
  .tab-content {
    height: auto;
    padding: 20px 10px;
  }

  .nav-pills {
    display: block;
  }

  .nav-pills>li.category-link {
    width: 100%;
    margin: 5px 0;
  }

  .nav-pills>li.category-link>a {
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
  }

  .nav-pills>li.active::after {
    display: none;
  }
}
