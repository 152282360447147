/* Container for the slider */
.slider-container {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    overflow: hidden;
    margin-bottom: 0; /* Add this line to remove bottom margin */
    padding-bottom: 0; /* Add this line to remove bottom padding */
}

/* Slides */
.slider-slide {
    display: none;
    width: 100%;
    margin-bottom: 0; /* Add this line to remove bottom margin */
    padding-bottom: 0; /* Add this line to remove bottom padding */
}

/* Next & previous buttons */
.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
}