  /* END Optional styles */
  .video-container {
    width: 550px;
    border-radius: 4px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  }

  .video-container .video-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .video-container video {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  @media screen and (max-width: 600px) {
    .video-container {
      width: 100%;
    }
    .video-container video {
      width: 100% !important;
      height: auto;
    }
  }

  .video-container video {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  .play-button-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    pointer-events: none;
  }

  .play-button-wrapper #circle-play-b {
    cursor: pointer;
    pointer-events: auto;
  }

  .play-button-wrapper #circle-play-b svg {
    width: 80px;
    height: 80px;
    fill: #fff;
    stroke: #fff;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    opacity: 0.9;
  }

  #circle-play-b {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s;
  }

  @import "~react-image-gallery/styles/css/image-gallery.css";

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 106px !important;
    width: 28px !important;
  }